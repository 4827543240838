"use client";
import { Button } from "@/components/ui/button";
import { useTranslations } from "next-intl";

export default function LoginError({
	error,
	reset,
}: {
	error: Error & { digest?: string };
	reset: () => void;
	params: { locale: string };
}) {
	const t = useTranslations("Login");

	return (
		<>
			<p className="pb-8 text-center text-red-light">
				{t("error-message")}
				{error.digest ? ` Digest: ${error.digest}` : null}
			</p>
			<Button variant={"link"} onClick={() => reset()}>
				{t("try-again")}
			</Button>
		</>
	);
}
